.graphViewContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
}
.graphViewGraphContainer {
  text-align: center;
  height: 10rem;
  border: 1px solid lightgrey;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.salesVsSpendHeaderContainer {
  text-align: center;
}
.graphViewTableContainer {
  flex: 1;
}
.campaignTabHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.campaignHeaderTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
