.header-container {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 3px solid lightgrey;
}
.logoImage {
  height: 4rem;
}
.header-right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.header-auth-btns {
  height: 100%;
}
