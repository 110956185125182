body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.dashboardContainer {
  position: relative;
}
.dashboardContainer .ant-tabs-tab-next-icon,
.dashboardContainer .ant-tabs-tab-prev-icon {
  display: none;
}
.dashboardContainer .ant-table-thead {
  font-size: 0.5rem;
}
.dashboardContainer .ant-table-thead th {
  padding-left: 1px;
  padding-right: 1px;
  text-align: center;
}
.dashboardContainer .ant-table-tbody {
  font-size: 0.5rem;
}
.dashboardContainer .ant-table-tbody td {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.dashboardGraphContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: 800px;
}
.tabPaneHeaderText {
  text-align: center;
}
.dashboardTableContainer {
  position: relative;
}
.addCampaignButtonContainer {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
}
.addCampaignButtonContainer .eachAddCampaignButton {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.profileButtonContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.negativeKeywordListSelector {
  float: left;
  margin-right: 5px;
}
.addNKLButton {
  margin: 5px 0;
}
.negativeKeywordListActions button {
  margin-top: 5px;
  margin-right: 5px;
}
.addBtn {
  margin-top: 10px;
}
.inline-block {
  display: inline-block;
}
.bulkActionsContainer {
  width: 100%;
  padding: 20px 0;
}

.ant-tabs-tab .ant-tag {
  margin-right: 0;
}

.ant-tabs-tab .anticon.ant-tag-close-icon {
  margin-right: 0;
  margin-left: 6px;
  vertical-align: middle;
}

.ant-tabs-content.ant-tabs-content-top {
  height: 100%;
}

.ant-tabs-nav-list {
  margin-bottom: -1px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-bottom: 2px solid rgba(232, 232, 232, 1);
  border-color: rgba(232, 232, 232, 1);
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 12px 16px 0;
}

/* Where does this !important rule come from that we need to override? */
.ant-table-thead th.ant-table-column-has-sorters.ant-table-column-sort::before {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.ant-table-summary > tr > td {
  border-bottom: 0;
}

.ant-table-body {
  scrollbar-width: none;
}

.ant-table-body::-webkit-scrollbar {
  display: none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 4px !important;
}

.ant-collapse > .ant-collapse-item:last-child {
  border-bottom: 0;
}

.ant-collapse-item.flex .ant-collapse-content {
  flex: 1;
  min-height: 0;
}

.ant-collapse-item.flex .ant-collapse-content-box {
  height: 100%;
}

.ant-modal-content .ant-modal-footer {
  padding: 16px 24px;
}

.tab-content-ant-table.ant-table-no-top-border .ant-table-small {
  border-top: 0;
}
