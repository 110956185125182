.bulk-bid-adjuster .ant-input-number {
  width: 100%;
}
.bulk-bid-adjuster textarea {
  height: 60px;
  width: 100%;
}
.ant-row.headline {
  padding-bottom: 20px;
}
